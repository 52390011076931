import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addArCof',
  components: {},
  watch: {},
  data() {
    return {
      editMode: false,
      isSuccess: false,
      responseMsg: [],
      showAlert: false,
      addArCofData: [
        {
          waybill: null,
          cof_batch: null,
          cof_cat: null,
          legal_entity: null,
          le_id: null
        }
      ],
      addArCofFields: [
        {
          key: 'legal_entity'
        },
        {
          key: 'cof_cat',
          label: 'COF Category'
        },
        {
          key: 'waybill',
          label: 'Way Bill'
        },
        {
          key: 'cof_batch',
          label: 'COF Batch'
        },
        {
          key: 'add',
          stickyColumn: true,
          class: 'stickyColumn'
        },
        {
          key: 'remove',
          stickyColumn: true,
          class: 'stickyColumn'
        }
      ],
      showValueSetModal: false,
      specIndex: null
    };
  },
  mounted() {
    this.editMode = true;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.validateMandatoryFields();
        }
      }
    });
  },
  methods: {
    validateMandatoryFields() {
      const objRef = this.addArCofData.filter(element => {
        if (element.le_id && element.waybill && element.cof_cat) {
          return element;
        } else {
          if (!element.le_id || !element.waybill || !element.cof_cat) {
            alert('Please Select All Mandatory Fields');
          }
        }
      });
      if (objRef.length === this.addArCofData.length) {
        this.addEditArCofData();
      }
    },
    addNewRow(index) {
      if (this.editMode) {
        this.addArCofData.push({
          legal_entity: null, //this.addArCofData[index].legal_entity,
          le_id: null, //this.addArCofData[index].le_id,
          waybill: null,
          cof_batch: this.addArCofData[index].cof_batch,
          cof_cat: this.addArCofData[index].cof_cat
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.addArCofData.splice(index, 1);
      }
    },
    addEditArCofData() {
      const costDetails = this.addArCofData.map(elem => {
        return {
          waybill: elem.waybill,
          cof_batch: elem.cof_batch,
          cof_cat: elem.cof_cat,
          cof_id: 0,
          le_id: elem.le_id
        };
      });
      const payload = {
        data: costDetails ? costDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/addEditArCofData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
     if (this.vsetCode === appStrings.VALUESETTYPE.COF_CAT_BY_LE) {
          this.parent_value_set_id = this.addArCofData[index].le_id;
        }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.COF_CAT_BY_LE) {
        this.addArCofData[this.specIndex].cof_cat =item.value_code;
        this.addArCofData[this.specIndex].cof_cat = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.addArCofData[this.specIndex].legal_entity = item.value_code;
        this.addArCofData[this.specIndex].le_id = item.value_set_dtl_id;
      }
      this.addArCofData = JSON.parse(JSON.stringify(this.addArCofData));
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'COF_CAT_BY_LE') {
        this.addArCofData[index].cof_cat = null;
      } else if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.addArCofData[index].legal_entity = null;
        this.addArCofData[index].le_id = null;
        this.addArCofData[index].cof_cat = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
