import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import addArCof from './addArCof';
import DatePicker from 'vue2-datepicker';
import uploadExcel from '@/app/components/shared/uploadExcel';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import store from '../../../../store';
export default {
  name: 'arCof',
  components: {
    addArCof,
    DatePicker,
    uploadExcel,
    sendForApproval,
    ApprovalHistory
  },
  watch: {
    currentPage: function() {
      this.getArCofData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getArCofData();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
    selectAll: function() {
      this.checkAll();
    }
  },
  data() {
    return {
      payload: null,
      loader: false,
      wayBill: null,
      clientName: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      arCofData: [
        {
          cof_amount: null,
          actual_client_name: null,
          is_cancelled: null,
          to_email: null,
          remarks: null
        }
      ],
      arCofFields: [
        {
          key: 'selectBox',
          stickyColumn: true,
          variant: 'primary',
          label: 'Select',
          class: 'text-center'
        },
        {
          key: 'initiate_approval',
          label: 'Approval',
          stickyColumn: true,
          class: 'text-center',
          variant: 'warning'
        },
        {
          key: 'waybill',
          label: 'Way Bill',
          class: 'text-center'
        },
        {
          key: 'cof_cat',
          label: 'COF Category'
        },
        {
          key: 'is_cancelled'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'status'
        },
        {
          key: 'payment_type'
        },
        {
          key: 'amount'
        },
        {
          key: 'client_name'
        },
        {
          key: 'actual_client_name'
        },
        {
          key: 'cof_amount',
          label: 'COF Amount'
        },
        {
          key: 'current_status'
        },
        {
          key: 'lr_number',
          label: 'LR Number'
        },
        {
          key: 'payment_type'
        },
        {
          key: 'doc_num',
          label: 'Document Number'
        },
        {
          key: 'cof_batch',
          label: 'COF Batch'
        },
        {
          key: 'cof_type',
          label: 'COF Type'
        },
        {
          key: 'cof_reason',
          label: 'COF Reason'
        },
        {
          key: 'remarks'
        },
        {
          key: 'to_email'
        }
      ],
      selectAllCheckBox: false,
      selectAll: false,
      showAddArCofModal: false,
      responseMsg: [],
      isSuccess: false,
      showAlert: false,
      status: {
        value: null,
        text: null
      },
      creationDate: [],
      documentNumber: null,
      cofBatch: null,
      showUploadExcel: false,
      showHistory: false,
      historyType: null,
      approvalStatus: false,
      sendFlag: false,
      showValueSetModal: false,
      defaultValue: {
        value: null,
        text: null
      },
      unsubscribe: null,
      eventId: null,
      approvStatus: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    this.setDefaultStatus();
    this.getEventId();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddArCofModal = true;
        }
        if (actionName === 'save' && !this.showAddArCofModal) {
          this.addEditArCofData();
        }
        if (actionName === 'download' && !this.showAddArCofModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'applyReceipt/getArCofData',
            'ar-cof',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setDefaultStatus() {
      const status = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.COF_STATUS,
        'SUCCESS'
      );
      status.then(key => {
        this.status.text = key[0].value_meaning;
        this.status.value = key[0].value_code;
      });
    },
    getArCofData() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        waybill: this.wayBill,
        client_name: this.clientName,
        status: this.status.value,
        approval_status: this.approvStatus.value,
        creation_from_date: commonHelper.formattedDate(this.creationDate[0]),
        creation_to_date: commonHelper.formattedDate(this.creationDate[1]),
        doc_num: this.documentNumber,
        cof_batch: this.cofBatch
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getArCofData', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.arCofData = response.data.data.page.map(data => {
              data.selectBox = false;
              return data;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clear() {
      this.wayBill = null;
      this.clientName = null;
      this.arCofData = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.status = this.defaultValue;
      this.creationDate = [];
      this.documentNumber = null;
      this.cofBatch = null;
      this.cofId = null;
      this.selectAll = false;
      this.approvStatus = this.defaultValue;
    },
    checkUncheckAll() {
      this.arCofData = this.arCofData.map(data => {
        data.selectBox =
          (data.approval_status !== 'INPROCESS' || data.status !== 'SUCCESS') &&
          this.selectAllCheckBox;
        return data;
      });
    },
    checkAll() {
      this.arCofData = this.arCofData.map(data => {
        data.initiate_approval =
          (data.approval_status !== 'APPROVED' || data.status !== 'SUCCESS') &&
          (data.approval_status !== 'DRAFT' || data.status !== 'INVALID') &&
          (data.approval_status !== 'INPROCESS' || data.status !== 'SUCCESS') &&
          this.selectAll;
        return data;
      });
    },
    showHideArCofModal(flag) {
      this.showAddArCofModal = flag;
      if (!flag) {
        this.getArCofData();
      }
    },
    addEditArCofData() {
      const filterData = this.arCofData.filter(key => key.selectBox);
      const costDetails = filterData.map(elem => {
        return {
          waybill: elem.waybill,
          cof_id: elem.cof_id,
          cof_amount: elem.cof_amount,
          actual_client_name: elem.actual_client_name,
          to_email: elem.to_email,
          remarks: elem.remarks
        };
      });
      const payload = {
        data: costDetails ? costDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/addEditArCofData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    wayBillData() {
      const selectedData = this.arCofData.filter(data => data.selectBox);
      if (selectedData.length > 0) {
        const costDetails = selectedData.map(data => {
          return {
            waybill: data.waybill
          };
        });
        const payload = {
          data: costDetails ? costDetails : null
        };
        this.loader = true;
        this.$store
          .dispatch('applyReceipt/wayBillData', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.getArCofData();
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Select Data First');
      }
    },
    showHideUploadExcel(flag) {
      this.showUploadExcel = flag;
    },
    selectedApproval(item) {
      this.cofId = item.cof_id;
      if (item.is_cancelled) {
        this.selectAllCheckBox = false;
        this.selectBox = false;
      } else {
        if (item.approval_status === 'APPROVED') {
          this.approvalStatus = true;
        } else {
          this.approvalStatus = false;
        }
      }
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    cancelData() {
      const selectedData = this.arCofData.filter(data => data.selectBox);

      if (selectedData.length > 0) {
        const costDetails = selectedData.map(data => {
          return {
            cof_id: data.cof_id
          };
        });
        const payload = {
          data: costDetails ? costDetails : null
        };
        this.loader = true;
        this.$store
          .dispatch('applyReceipt/cancelData', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.getArCofData();
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Select Data First');
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.COF_STATUS) {
        this.status = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.APPROVAL_STATUS) {
        this.approvStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.status.value) {
        this.status = this.defaultValue;
      } else if (vsetCode === this.approvStatus.value) {
        this.approvStatus = this.defaultValue;
      }
    },
    workFlowData() {
      const selectedData = this.arCofData.filter(
        data => data.initiate_approval
      );
      if (selectedData.length > 0) {
        const costDetails = selectedData.map(data => {
          return {
            record_id: data.cof_id
          };
        });
        const payload = {
          event_id: this.eventId,
          record_list: costDetails ? costDetails : null
        };
        this.loader = true;
        this.$store
          .dispatch('applyReceipt/workFlowData', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.getArCofData();
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    deleteData() {
      const selectedData = this.arCofData.filter(data => data.selectBox);

      if (selectedData.length > 0) {
        const costDetails = selectedData.map(data => {
          return {
            cof_id: data.cof_id
          };
        });
        const payload = {
          data: costDetails ? costDetails : null
        };
        this.loader = true;
        this.$store
          .dispatch('applyReceipt/deleteData', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.getArCofData();
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    getEventId() {
      this.$store
        .dispatch('intWorkflow/getEventId', store.state.shared.menuId)
        .then(response => {
          if (response.status === 200) {
            this.eventId = response.data.data;
          }
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
